/* ~~~~~~~~ Layouts ~~~~~~~~~ */
div.modal-body {
    padding: 20px 20px;
    text-align: center;
    color: #415A4E;
    display: flex;
    flex-direction: column;
    align-items: center;
}

div.modal-body h4 {
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
    margin: 0;
}

div.modal-body form {
    width: 100%;
    margin: 25px 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 6px;
}

div.modal-body form input {
    width: 100%;
    margin: 0 0 20px;
    height: 25px;
    font-size: 12px;
}


div.modal-body div.page-indicator-cont {
    width: 27px;
    display: flex;
    justify-content: space-around;
}

div.modal-body i {
    font-size: 8px;
}

div.modal-body i.discolor {
    opacity: 0.5;
}