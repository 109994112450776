@import url('https://fonts.googleapis.com/css2?family=Varta:wght@400;700&display=swap');



.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.App {
  /* max-width: 1148px; */
  margin: 0 auto;
}

/* //////////////////// */
/* Custom Styles */
/* //////////////////// */

/* BODY */

body, html {
  max-width: 100%;
  overflow-x: hidden;
}

body {
  font-family: 'Varta', sans-serif !important;
  /* background: linear-gradient(180deg, #FEFCF4 0%, #EFEDE6 100%); */
}

button {
  border-radius: 7px !important;
  background-color: #F87093;
  border: none;
  color: #FFFFFF;
  font-weight: 700;
  font-size: 18px;
}

/* Nav bar */
div.nav-cont {
  width: 100%;
}

p.bold {
  font-weight: 700 !important;
}

div.container-fluid {
  padding: 0;
  display: flex;
  justify-content: center;
  background: #FEFCF4;
}

div.nav {
  padding: 0;
  width: 100%;
  max-width: 1143px;
  background-color: #3C5068;
  color: #FFFFFF;
  ;
}

div.nav h1 {
  font-size: 25px;
  margin: 4px 0 0;
}

div.home-page {
  color: #2D2B26;
  background-color: inherit;
}

div.mobileCont {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-left: 5px;
  margin: 50px 25px 0 16px;
}

div.hamburger-cont {
 display: flex;
 justify-content: center;
 align-items: center;
}

div.nav button {
  width: 135px;
  height: 30px;
}

div.options-cont {
  display: flex;
  justify-content: center;
  width: 100%;
}

div.options-cont ul {
  padding: 10px 25px 0;
  display: flex;
  justify-content: space-around;
  width: 100%;
}

div.options-cont li {
  display: block;
  list-style: none;
  padding: 5px 0;
  color: #786870;
  font-weight: 700;
  font-size: 20px;
}

div.nav svg {
  background-color: black;
  fill: #282c34
}

/* Landing Page */

div#landing-cont-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

div.landing-cont {
  margin: 0 0 80px 0;
  max-width: 1143px;
  background-clip: border-box;
}

div.top-background {
  background: linear-gradient(180deg, #FEFCF4 0%, #EFEDE6 100%);
  padding: 50px 0 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

div.top-background-cont {
  max-width: 1143px;
}

div.landing-page-cont {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

h1.hero {
  padding: 0 34px;
  margin: 0;
  font-size: 30px;
  line-height: 44px;
  font-weight: 400;
}

a.carousel-control-prev, a.carousel-control-next {
  display: none;
}

div.subtext-marketplace-cont {
  padding: 30px 34px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

div.subtext-marketplace-cont p {
  margin: 0 0 40px;
}

div.markets-cont {
  display: flex;
  justify-content: space-between;
  align-items: center;
  mix-blend-mode: luminosity;
  width: 100%;
}

div.landing-cont div.search-cont {
  display: flex;
  flex-direction: column;
}

div.search-title {
  height: 80px;
  background-color: #F386A2;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 21;
  cursor: pointer;
}

div.carousel-cont {
  height: 365px;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

div.carousel-cont div.carousel-slide {
  max-height: 100%;
}

div.carousel-inner img {
  max-height: 357px;
  max-width: 100%;
  width: auto !important;
}

div.landing-cont h2 {
  font-family: Varta;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #FFFFFF;
  margin: 0;
}

div.search {
  background-color: #FFFFFF;
  padding: 40px 0 0;
}

div.search img {
  margin-bottom: 30px;
}

div.landing-cont div.search p {
  margin: 3px 35px 0;
  padding: 3px;
  color: #415A4E;
}

div.landing-cont div.search form {
  margin: 31px 43px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

div.landing-cont div.search form input {
  margin: 0 0 25px;
  width: 100%;
}

div.landing-cont div.search form input:nth-of-type(4) {
  margin: 20px 0 80px;
  height: 32px;
  background-color: #F386A2;
  color: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  border: none;
  font-size: 16px;
}

div.landing-cont div.explore-banner{
  height: 73px;
  width: 100%;
  background-color: #3C5068;
  display: flex;
  align-items: center;
  justify-content: center;
}

div.landing-cont h3 {
  font-size: 24px;
  color: #FFFFFF;
  margin: 0;
  line-height: 35px;
}

div.landing-cont div.inspiration-banner {
  height: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 15px;
}

div.landing-cont div.inspiration-subtext-cont {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 10px;
}

div.landing-cont div.inspiration-banner.second {
  background: #ADABA4;
}

div.landing-cont div.inspiration-banner.second a {
  color: #FEFCF4;

}

div.landing-cont div.inspiration-banner div.inspiration-img-cont {
  max-height: 178px;
  height: 100%;
  width: auto;
  margin: 15px 0 7px;
}

div.landing-cont div.inspiration-banner div.inspiration-img-cont img {
  max-height: 100%;
}

div.landing-cont div.inspiration-banner h5 {
  max-width: 169px;
  margin: 0;
}

div.landing-cont div.inspiration-banner div.link-cont {
  display: flex;
  align-items: center;
  grid-gap: 8px;
}

div.landing-cont div.inspiration-banner div.link-cont h5 {
  margin: 3px 0 0 0;
}

div.landing-cont div.inspiration-banner a {
  color: #F87093;
}

div.landing-cont div.inspiration-banner.third {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  grid-gap: 16px;
  height: 270px;
}

div.landing-cont div.inspiration-banner.third div {
  max-width: 50%;
  width: 100%;
}

div.landing-cont div.inspiration-banner.third p {
  font-size: 12px;
  line-height: 17.6px;
  margin: 0;
  font-weight: normal;
  width: 100%;
}

div.landing-cont div.inspiration-banner.third div.inspiration-img-cont {
  max-height: 192px;
}

div.landing-cont div.inspiration-banner.third img {
  width: 152px;
}

div.landing-cont div.inspiration-banner.third div.testimonial-cont {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  padding: 0 5px 0 0;
  grid-gap: 10px;
}

div.landing-cont div.inspiration-banner.third div.testimonial-author {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 100%;
}


div.landing-cont div.jumbotron {
  background-image: url("./assets/Walhalla.jpg");
  background-position: cover;
  height: 354px;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-color: #404D58;
  background-blend-mode: luminosity;
}

div.landing-cont div.jumbotron div.container {
  display: flex;
  justify-content: center;
}

div.landing-cont div.jumbotron h2 {
  font-family: Varta;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 52px;
  color: #FFFFFF;
  text-align: center;
  margin: 0 16px;
  width: 251px;

}

div.landing-cont footer {
  height: 101px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #3C5068;
}

div.landing-cont footer p {
  margin: 0;
  color:#FFFFFF;
  font-size: 12px; 
}

div.modal-body h2 {
  font-size: 36px;
  color: #F87093;
  margin: 0;
  vertical-align: middle;
}

div.modal {
  margin: 51px 0;
  width: 100%;
}

div.modal-body img {
  margin: 10px 0 24px;
}

div.modal p.alert {
  color: red;
  padding: 10px 0 0;
}

/* Keyword Builder  */

div.keyword-cont {
  width: 100%;
}

div.keyword-cont div.completed-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

div.keyword-cont div.completed-form div.keywords-cont {
  display: flex;
  width: 100%;
}

div.keyword-cont div.completed-form div.keywords-cont div.keyword-cont {
  width: 33%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  padding: 0 8px 0;
  margin: 0px 4px 0 0;
  background: #FFFFFF;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
}

div.keyword-cont div.completed-form div.keywords-cont div.keyword-cont h5 {
  font-size: 13px;
  margin: 0;
}

div.keyword-cont div.datas-cont {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 13px 0;
}

div.keyword-cont div.datas-cont div.data-cont {
  width: 33%;
  display: flex;
  justify-content: center;
  align-items: center;
}

div.keyword-cont div.datas-cont div.data-cont i {
  margin: 0 0 0 10px;
}

div.keyword-cont div.datas-cont div.data-cont h4 {
  font-size: 17px;
  color: #1D1F22;
  margin: 3px 0 0 0;
  vertical-align: middle;
  display: flex;
  align-items: center;
}

div.keyword-cont div.completed-form button {
  margin: 0;
  text-align: center;
  padding-top: 1px;
}

div.keyword-cont input {
  width: 100%;
  height: 50px;
  margin: 0 0 20px;
  font-weight: 400;
  border-radius: 5px;
  border: 2px solid #79B990;
  background-color: #FFFFFF;
}

div.keyword-cont input::placeholder {
  color: #BDB2B6;
}

div.keyword-cont span.dollar{
  position: absolute;
  margin-left: 4px;
  margin-top: 13px;
  color: #BDB2B6;
}

div.keyword-cont span.dollar-filled{
  position: absolute;
  margin-left: 4px;
  margin-top: 13px;
  color: black;
}

div.keyword-cont input.budget {
  padding: 0 0 0 13px;
}

div.keyword-cont form {
  text-align: left;
  width: 90%;
  margin: 0 auto;
}

div.keyword-cont h5 {
  font-weight: 700;
  color: #786870;
}

div.keyword-cont div.keywords {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

div.keyword-cont input.keyword {
  width: 32%;
}

div.keyword-cont button {
  background-color: #AFD5BD;
  color: #63545A;
  font-weight: 700;
  border: 2px solid #AFD5BD;
}

div.keyword-cont button:hover {
  background-color: #F7BFB4;
  border: #99E9FF;
  color: #79676E;
  text-decoration: none;
}

div.keyword-cont button.finalize {
  width: 400px;
  font-size: 30px;
  font-weight: 700;
}

/* Product Carousel */
div.sum-total-cont {
  position: absolute;
  margin: 10px auto;
  width: 100%;
}

div.sum-total-cont .sum-total {
  color: #584B50;
}

div.sum-total-cont .over {
  color: #DE2E2B;
}

div.product-carousel {
  margin: 0 2px 3px;
  padding: 0 0 0 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

div.product-carousel div.see-more {
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #554D56;
  cursor: pointer;
  border-radius: 3px;
} 

div.product-carousel div.see-more h5 {
  color: #FFFFFF;
  display: flex;
  margin: 0 0;
}

div.product-carousel div.see-more i {
  position: absolute;
  margin: 25px auto 0;
  color: #554D56;
}

/* Products */
div.loading-cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: #847178;
}

div.row {
  display: flex;
  justify-content: space-around;
}

div.card {
  margin: 0 auto;
  width: 100% !important;
  height: 100%;
  flex-direction: row;
}

div.card p.card-text {
  font-size: 15px;
  cursor: pointer;
}

div.product {
  width: 100%;
  margin: 0 2px 0;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.25);
  flex-basis: auto;
  height: 264px;
  display: flex;
  align-items: center;
  padding: 0;
}

div.product div.overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: rgba(85,77,86,0);
  width: 100%;
  height: 100%;
  z-index: 1;
}

div.product div.selected {
  background-color: rgba(85,77,86,0.6);
}

div.product div.img-button-cont {
  height: 100%;
  padding: 15px 0;
  width: 47%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

div.product div.img-cont {
  width: 100%;
  height: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

div.product div.img-cont img {
  max-width: 100%;
  max-height: 140px;
  width: auto;
  height: auto;
  cursor: pointer;
}

div.product div.button-cont {
  z-index: 2;
  margin: 10px 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
}

div.product div.button-cont button {
  font-size: 14px !important;
  height: 31px !important;
  width: 120px !important;
  padding: 0;
  border: 0;
}

div.product div.button-cont button.pink {
  background: #F87093;
  color: #FFFFFF;
}

div.product div.button-cont button.white {
  background: #FFFFFF;
  border: 1px solid #3C5068;
  color: #3C5068;
}

div.product div.img-cont div.img-link-overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 2;
  cursor: pointer;
  width: 100%;
  height: 100%;
}

div.product div.card-body {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 15px 0 0 7px;
}

div.product div.card-body div.text-cont {
  position: relative;
  padding: 0;
  z-index: 2;
}

div.product div.card-body p.card-text {
  text-align: left;
  font-size: 12px;
  line-height: 18px;
  z-index: 3;
  margin: 0 6px 7px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
}

div.product div.card-body p:hover {
  text-decoration: underline;
}

div.product div.card-body div.text-link-overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 2;
  cursor: pointer;
  width: 100%;
  height: 100%;
}

div.product div.ratings-cont {
  display: flex;
  justify-content: center;
  position: relative;
}

div.product div.ratings-cont i {
  color: #FB8200;

}

div.product div.ratings-cont p {
  margin: 0 0 0 4px;
  font-size: 14px;
  line-height: 22px;
  color: #6E6C66;
}

div.product div.price-button-cont {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 7px 0 0;
}

div.product div.price-button-cont div.shipping-delivery-cont {
  margin: 0 0 20px;
}

div.product div.price-button-cont div.shipping-delivery-cont p {
  text-align: left;
  margin: 0;
  color: #787885;
  font-size: 10px;
}

div.product img.prime-logo {
  width: 45px;
  padding: 15px 0 20px;
}

div.product button {
  background-color: #AFD5BD;
  color: #63545A;
  font-weight: 700;
  border: 2px solid #AFD5BD;
  z-index: 2;
  width: 95%;
}

div.product div.price-button-cont button.selected {
  background-color: #F7BFB4;
  border-color: #F7BFB4;
}

div.product div.price-button-cont div.h5 {
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
}

div.product div.price-button-cont div.card-title {
  margin-bottom: 0px;
}

/* Cart */
div.cart-cont {
  margin: 20px 0 52px;
  color: #584B50;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

div.cart-cont div.return-title-cont {
  display: flex;
  align-items: center;
  margin: 0 0 16px 18px;
  grid-gap: 35px;
}

div.cart-cont div.subtotal {
  margin: 0 0 20px 87px;
}

div.cart-cont h2 {
  font-size: 24px;
  line-height: 24px;
  margin: 0;
}

div.cart-cont h3 {
  font-size: 18px;
}

div.cart-cont div.description p {
  text-align: left;
  margin: 0 18px 16px 16px;
  font-size: 16px;
  line-height: 23.2px;
}

div.cart-cont div.search-title {
  height: 52px;
}

div.cart-cont div.search-title h4 {
  font-size: 16px;
  color: #FFFFFF;
  margin: 0;
}

/* Selected Products */
div.cart-cont div.product {
  height: 250px;
}

div.cart-cont div.button-cont {
  height: 100px;
}

/* Conditional Rendering */
.hidden {
  display: none !important
}

@media screen and (min-width: 768px) {

  /* Nav */

  div.nav div.mobile-cont {
    margin: 60px 40px 0;
  }

  div.nav h1 {
    font-size: 36px;
  }

  div.nav button {
    width: 159px;
    height: 42px;
  }
  
  /* Product Carousel */
  div.top-background {
    padding: 80px 0 0;
  }

  h1.hero {
    width: 415px;
    font-size: 48px;
    font-weight: 400;
    padding: 0;
    line-height: 69.61px;
  }

  div.carousel-cont {
    height: 400px;
  }

  div.carousel-inner img {
    max-height: 400px;
  }

  div.subtext-marketplace-cont {
    padding-bottom: 125px;
  }

  div.subtext-marketplace-cont p.tablet-subtext {
    font-size: 24px;
    line-height: 35px;
    font-weight: 400;
    width: 560px;
    margin-bottom: 48px ;
  }

  div.markets-cont {
    max-width: 550px;
  }

  div.landing-cont div.search {
    padding-top: 100px;
  }

  div.landing-cont div.search img {
    margin-bottom: 48px;
  }

  div.landing-cont div.search p {
    font-size: 36px;
    margin: 0 40px;
  }

  div.landing-cont div.search form {
    margin: 48px 57px 0;
  }

  div.landing-cont div.search form input {
    height: 40px;
    margin-bottom: 30px;
  }
  
  div.landing-cont div.search form input:nth-of-type(4) {
    height: 48px;
    font-size: 24px;
    font-weight: 700;
    line-height: 35px;
    width: 360px;
    margin: 10px 0 100px;
  }

  div.landing-cont div.explore-banner {
    height: 181px;
  }

  div.landing-cont div.explore-banner h3 {
    font-size: 48px;
    line-height: 70px;
    margin-top: 15px;
  }

  div.landing-cont div.inspiration-banner {
    height: 480px;
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 15px;
    justify-content: center;
  }

  div.landing-cont div.inspiration-banner.first div, div.landing-cont div.inspiration-banner.second div {
    width: 48%;
  }
  
  div.landing-cont div.inspiration-banner.first {
    flex-direction: row-reverse;
  }
  
  div.landing-cont div.inspiration-banner div.inspiration-img-cont {
    max-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
  }
  
  div.landing-cont div.inspiration-banner div.inspiration-img-cont img {
    width: 100%;
    height: auto;
  }

  div.landing-cont div.inspiration-banner div.inspiration-subtext-cont {
    max-width: 316px;
    width: 100%;
    margin: 0 0 0 40px;
    align-items: flex-start;
  }
  
  div.landing-cont div.inspiration-banner h5 {
    font-size: 36px;
    line-height: 52px;
    font-weight: 700;
    max-width: 100%;
    text-align: left;
  }
  
  div.landing-cont div.inspiration-banner div.link-cont {
    width: 100%;
  }
  
  div.landing-cont div.inspiration-banner div.link-cont h5 {
    font-size: 18px;
    line-height: 26px;
    margin: 0;
  }
  
  div.landing-cont div.inspiration-banner.second div.inspiration-subtext-cont {
    margin: 0 45px 0 0;
  }

  div.landing-cont div.inspiration-banner.third {
    height: 650px;
    justify-content: space-between;
    grid-gap: 40px;
  }

  div.landing-cont div.inspiration-banner.third div.inspiration-img-cont {
    width: 45%;
    max-height: 357px;
    height: 100%;
    display: flex;
    justify-content: flex-start;
  }

  div.landing-cont div.inspiration-banner.third div.inspiration-img-cont img {
    width: auto;
  }

  div.landing-cont div.inspiration-banner.third div.testimonial-cont {
    width: 55%;
    max-width: none;
  }

  div.landing-cont div.inspiration-banner.third div.testimonial-cont p {
    font-size: 24px;
    line-height: 35px;
  }

  div.landing-cont div.footer-image {
    height: 479px;
  }

  div.landing-cont div.footer-image h2 {
    width: 469px;
    font-size: 48px;
    line-height: 70px;
  }

  div.landing-cont div.jumbotron {
    height: 100%;
    grid-gap: 80px;
  }

  div.landing-cont div.footer-image div.markets-cont {
    color: white;
    max-width: 600px;
  }

  div.landing-cont div.footer-image div.markets-cont img {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    filter: brightness(0) invert(1);
  }

  div.landing-cont footer {
    flex-direction: row;
    grid-gap: 5px;
  }

  div.landing-cont footer p {
    font-size: 16px;
  }

  /* Products */

  div.nav.non-home {
    padding-bottom: 20px;
  }

  div.product-carousel {
    align-items: center;
    margin: 0 7px;
  }

  div.product-carousel div.container {
    padding: 0;
    max-width: 100%;
    display: flex;
    justify-content: center;
  }

  div.row {
    max-width: 100%
  }

  div.product {
    width: 40%;
  }

  div.keyword-cont div.see-more {
    width: 100%;
    margin: 7px 0;
  }

  /* Final Cart */
  div.cart-cont {
    padding: 0 0 10px;
  }


  /* CTA */
  div.landing-cont div.search-title h2 {
    font-size: 24px;
  }

}

@media screen and (min-width: 1024px) {
  /* Nav */

  div.nav div.mobileCont {
    margin: 66px 80px 0;
  }

  div.nav h1 {
    font-size: 40px;
  }

  /* Landing Page */

  div.landing-page-cont {
    flex-direction: row;
    justify-content: center;
  }

  h1.hero {
    font-size: 40px;
    text-align: left;
    margin: 0 0 0 80px;
    line-height: 70px;
    max-width: 340px;
  }

  div.carousel-cont {
    width: 625px;
  }

  div.subtext-marketplace-cont {
    padding-top: 73px;
  }

  div.subtext-marketplace-cont p.tablet-subtext {
    width: 904px;
    font-size: 32px;
    line-height: 52px;
    margin-bottom: 73px;
  }

  div.markets-cont {
    max-width: 900px;
  }

  div.landing-cont div.search {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  div.landing-cont div.search p {
    max-width: 700px;
  }

  div.landing-cont div.search form {
    max-width: 721px;
    width: 100%;
  }

  div.landing-cont div.inspiration-banner div.inspiration-subtext-cont {
    max-width: 416px;
  }

  div.landing-cont div.inspiration-banner h5 {
    /* font-size: 48px; */
    /* line-height: 70px; */
  }

  div.landing-cont div.inspiration-banner.third div.inspiration-img-cont {
    justify-content: center;
    width: 35%;
  }

  div.landing-cont div.inspiration-banner.third {
    grid-gap: 5px;
    justify-content: center;
  }

  div.landing-cont div.inspiration-banner.third div.testimonial-cont {
    width: 65%;
    max-width: 650px;
  }

  div.landing-cont div.inspiration-banner.third div.testimonial-cont p span {
    font-size: 36px;
    line-height: 60px;
  }

  div.landing-cont div.footer-image h2 {
    /* font-size: 64px; */
    width: 671px;
    /* line-height: 93px; */
  }

  div.landing-cont div.footer-image div.markets-cont {
    max-width: 850px;
  }

  div.landing-cont div.search-title {
    height: 100px;
    position: relative;
  }
  
  div.landing-cont div.search-title h2 {
    font-size: 32px;
  }

  div.landing-cont {
    margin: 0;
  }

  /* PRODUCTS */

  div.product {
    width: 30%;
    max-width: 380px;
  }

  /* SELECTED ITEMS */
  div.cart-cont div.description-selections-cont {
    display: flex;
    flex-direction: column;
    align-items: center;

  }

  div.cart-cont div.description-selections-cont p {
    text-align: center;
    max-width: 750px;
  }

}
